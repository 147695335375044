import { Component, DestroyRef, OnInit } from '@angular/core';
import { FlowsService } from "src/app/services/flows.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Flow } from 'src/app/utilities/models/ticket/flow/flow';
import { FlowCategory } from 'src/app/utilities/models/ticket/flowCategory/flowCategory';
import { FlowCategoryWithChildren } from 'src/app/utilities/models/ticket/flowCategory/flowCategoryWithChildren';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {
  searchString: string = '';
  flows: Flow[] = [];
  flowCategories: FlowCategory[];
  flowCategoriesLoading: boolean = false;
  filteredFlows: Flow[] = [];
  filteredItems: any[];
  visibleExpandedCategory: number | undefined = undefined;
  categoryTree: FlowCategoryWithChildren[] = [];
  nestingLevels: number = 1;

  constructor(private flowService: FlowsService,
              private destroyRef: DestroyRef) { }

  ngOnInit(): void {
    this.getFlowCategories();
  }

  private getFlowCategories() {
    this.flowCategoriesLoading = true;
    this.flowService.getFlowCategoriesWithFlows()
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe({
      next:(response: any) => {
        this.flowCategories = response.data.map( (row: any) => {
          const ancestryPathNesting = row.attributes.ancestry?.split('/')?.length + 1 || 1;
          if(ancestryPathNesting > this.nestingLevels) this.nestingLevels = ancestryPathNesting;

          if(row.relationships?.flows?.data?.length){
            const flows = row.relationships.flows.data.map((flow: any) => {
              const includedObj = response.included.find((item: any) => item.type == flow.type && item.id == flow.id)
              const flowData = {
                ...includedObj,
                relationships: {
                  flow_categories: [
                    {
                      ...row
                    }
                  ]
                }
              }

              return new Flow(flowData, response.included)
            });

            this.flows = [
              ...this.flows,
              ...flows
            ]
          }

          return new FlowCategory(row, response.included);
        });
        this.setupCategoryTree();
        this.flowCategoriesLoading = true;
      },
      error: (error) => {
        console.log(error);
        this.flowCategoriesLoading = false;
      }
    });
  }

  filterSectionsAndItems() {
    this.filteredFlows = this.flows.filter((flow: Flow) => {
      return flow.attributes.title.toLowerCase().includes(this.searchString.toLowerCase()) ||
             flow.relationships?.flow_categories?.some(flowCategory => flowCategory.attributes?.title.toLowerCase().includes(this.searchString.toLowerCase()))
    });

    //add other when empty result
    if(!this.filteredFlows.find((flow: Flow) => flow.attributes.title == 'other')){
      const flowOther = this.flows.find( (flow: Flow) => flow.attributes.title.toLowerCase() == 'other' );
      if(flowOther) {
        this.filteredFlows.push(flowOther);
      }
    }
  }

  showCategory(categoryId: number | undefined, key: string | undefined) {
    if (this.visibleExpandedCategory == categoryId) {
      this.visibleExpandedCategory = undefined;

      return;
    }
    this.visibleExpandedCategory = categoryId;

    if(key) {
      const elem = document.querySelector('#'+key);
      if (elem != null) {
        setTimeout(() => {
          elem.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }, 10)
      }
    }
  }

  private setupCategoryTree() {
    this.categoryTree = this.flowCategories.filter((flowCategory: FlowCategory) => flowCategory.attributes.parent_id == null)
      .map((flowCategory: FlowCategory) => {
        return {
          id: flowCategory.id,
          category: flowCategory,
          children: this.getFlowCategoryChildren(flowCategory.id)
        }
      }) ?? [];
  }

  private getFlowCategoryChildren(parentId: number): FlowCategoryWithChildren[] {
    return this.flowCategories.filter((flowCategory: FlowCategory) => flowCategory.attributes.parent_id == parentId).map((flowCategory: FlowCategory) => {
      return {
        id: flowCategory.id,
        category: flowCategory,
        children: this.getFlowCategoryChildren(flowCategory.id)
      }
    }) ?? []
  }
}

<nz-layout class="tickets-layout">
  <nz-header class="main-layout-header">
    <mainHeader>
      <nz-breadcrumb breadcrumb nzSeparator="·">
        <nz-breadcrumb-item><a [routerLink]="['/app']">EMPIST 360</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'solutions']">Apps</a></nz-breadcrumb-item>
        <nz-breadcrumb-item>Additional Services</nz-breadcrumb-item>
      </nz-breadcrumb>
    </mainHeader>
  </nz-header>
  <nz-content class="inner-layout">
    <div nz-row nzJustify="start">
      <div nz-col>
        <span [routerLink]="['/app','solutions']" class="back-button">
          <i nz-icon nzType="left" [nzTheme]="'outline'"></i>
          <span class="txt-grey"> Back</span>
        </span>
      </div>
    </div>
    <div nz-row>
      <div nz-col nzSpan="24">
        <div class="list-container">
          <div nz-row nzJustify="start">
            <div nz-col [nzSm]="24" nzOffset="0" class="text-center">
              <h1>Services <span *ngIf="!loading">({{ total }})</span></h1>
            </div>
          </div>
          <div nz-row nzJustify="center">
            <div nz-col [nzSm]="24" nzOffset="0" class="text-center">
              This is the list of services that are managed manually.<br>
              This information will be displayed to each account based on input.
            </div>
          </div>
          <nz-divider></nz-divider>
          <div nz-row class="posts-list">
            <div nz-col nzSpan="12" class="text-right"></div>
            <div nz-col nzSpan="12" class="text-right">
              <button nz-button class="btn-blue" nzShape="round" [routerLink]="['/app','additional-services','new']">
                New Service
              </button>
            </div>
          </div>
          <div nz-row class="list-description">
            <div nz-col nzSpan="24">
              <nz-table [nzData]="services"
                        [nzFrontPagination]="false"
                        [nzLoading]="loading"
                        [nzTotal]="total"
                        [nzPageSize]="pageSize"
                        [nzPageIndex]="pageIndex"
                        [nzBordered]="false"
                        nzShowSizeChanger
                        [nzPageSizeOptions]="[20,50,100]"
                        (nzQueryParams)="onQueryParamsChange($event)">
                <thead>
                <tr>
                  <th nzColumnKey="logo">Logo</th>
                  <th nzColumnKey="title">Service Title</th>
                  <th nzAlign="center">Integrated</th>
                  <th nzColumnKey="action" [nzWidth]="'40'" nzAlign="center">Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let service of services" [routerLink]="['/app', 'additional-services', service.id]" class="link">
                  <td width="80"><img [src]="getLogoByService(service)" /></td>
                  <td class="service-title">{{ service.attributes.title }}</td>
                  <!--<td><a class="link" (click)="removeService(service.id)">Remove</a></td>-->
                  <td nzAlign="center" class="service-is-integrated">{{ service.attributes.external_identifier ? 'Yes' : 'No'}}</td>
                  <td nzAlign="center"><a><span nz-icon nzType="edit" nzTheme="outline"></span></a></td>
                </tr>
                </tbody>
              </nz-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nz-content>
</nz-layout>
